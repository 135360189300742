@tailwind base;
@tailwind components;
@tailwind utilities;

/* App.css */
.canvasContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.content {
  position: relative;
  z-index: 1; /* Ensures content is above the canvas */
}
